import axios from "axios";

export const Services = axios.create({
  baseURL: "https://backend.givepay.net/api/v1",
  //   baseURL: "http://192.168.1.3:5050/api/v1",
  timeout: 10000,
  headers: {
    Accept: "application/json",
  },
});
