import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "./header";
import QrCode from "./qr-code";
import Notice from "./notice";
import Footer from "./footer";
import { getInfo } from "../api/all-service";
import GivePayLogo from "../image/logo.png";
import Background from "../image/background.png";

export default function CouponPage() {
  const [isLoading, setLoading] = useState(true);
  const [isGifVisible, setIsGifVisible] = useState(true);
  const [data, setData] = useState(null);
  let { token } = useParams();
  if (!isLoading) setTimeout(() => setIsGifVisible(false), 2000);
  useEffect(() => {
    const getTokenInfo = async () => {
      try {
        const payload = { encoded: token };
        const res = await getInfo(payload);
        setData(res?.data?.data);
        // console.log("res?.data?.data", res?.data?.data);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    };
    getTokenInfo();
  }, []);

  const GIFs_Data = {
    0: require("../image/GIFs/Confetti_2_Flex.gif"),
    1: require("../image/GIFs/Fireworks_Flex_2.gif"),
    2: require("../image/GIFs/Valentines_Flex.gif"),
    3: require("../image/GIFs/Balloons_Flex.gif"),
    4: require("../image/GIFs/Clapping_Flex.gif"),
    5: require("../image/GIFs/SaleDiscount_Flex.gif"),
    6: require("../image/GIFs/Sale_Circular_Flex.gif"),
  }

  return (
    <>
      {isLoading && (
        <div className="loader-wrapper">
          <img src={GivePayLogo} />
          <div class="loader"></div>
        </div>
      )}
      {!isLoading && (
        <div className="PosterWrapper">
          {isGifVisible && (
            <div className="blast-wrapper">
              {data?.gamification && data?.gamification?.status ? (
                <img
                  src={GIFs_Data[data.gamification?.phoneGami]}
                  height="100%"
                  width="100%"
                />
              ) : ""}
            </div>
          )}

          <Header data={data?.data} />

          <div
            style={{
              justifyContent: "center",
              flexDirection: "column",
              display: "flex",
              height: "70vh",
            }}
          >
            <img
              src={Background}
              alt="Background"
              className="background-image"
            />
            <div className="body-part">
              {data?.gamification ? (
                <><QrCode token={token} data={data?.data} />
                  <Notice data={data} /></>
              ) : (
                <div style={{
                  margin: 30,
                  textAlign: "center",
                  fontSize: 30,
                  color: "red"
                }}>Coupon Not Found</div>
              )}
            </div>
            <a
              href="https://www.givepay.com/terms"
              style={{
                textAlign: "center",
                marginBottom: "10px",
                textDecoration: "underline",
                color: "#264594",
              }}
            >
              Terms of Use
            </a>
          </div>
          <Footer style={{ height: "10vh" }} />
        </div>
      )}
    </>
  );
}
