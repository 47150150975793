import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Router, Routes } from "react-router-dom";
import CouponPage from "./components/coupon-page";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/:token" element={<CouponPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
