import React from "react";
import footerLogo from "../image/footerLogo.png";

export default function Footer(props) {
  return (
    <div className="footer" style={{ ...props.style }}>
      <img src={footerLogo} />
    </div>
  );
}
