import React from "react";
import QRCode from "react-qr-code";
import moment from "moment";

export default function QrCode(props) {
  return (
    <div className="qr-code-container-wrapper">
      <QRCode
        className="code"
        value={props.token}
        id={"QRCode"}
        viewBox="0 0 256 256"
      />
      <div className="content">
        <h1>Save ${Number(props?.data?.discountPrice).toFixed(2)}</h1>
        <h4 className="expiry">
          Expiration: {moment(props?.data?.expiryDate).format("MMM DD, YYYY")}
        </h4>
      </div>
    </div>
  );
}
