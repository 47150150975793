import React from "react";

export default function Notice(props) {
  if (props?.data?.merchant) {
    return (
      <div className="noticeWrapper">
        Save on your next visit and{" "}
        {props?.data?.merchant?.opt_in_double_discount
          ? "Cash"
          : "Card or Cash"}{" "}
        purchase of ${Number(props?.data?.data?.transactionAmount).toFixed(2)} or more
      </div>
    );
  } else {
    return <></>;
  }
}
